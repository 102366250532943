<template>
  <div class="headerclass">
       <div v-if="!loggedIn">
         <img alt="school logo" :src="LogoPath"  class="loginlogo" >
       </div>
       <div v-if="loggedIn" class="horizFlexStretch">
          <div class="vertFlexTop leftjustified">
            <img alt="school logo" :src="LogoPath"  class="logo" v-if="LogoPath!=''">
            <label class="headertextbold">{{loggedInUser.LocationName}}</label>
            <!-- <transactions :showButton="trueParam"  v-if="ShowTransactions" buttonClass="shortbutton"/> -->
         </div>
         
         <div class="vertFlexTop rightjustified" v-if="requireLogin">
           <label class="headertextbold">{{loggedInUser.name}}</label>
           <!-- <label class="headertext">{{loggedInUser.email}}</label> -->
           <linkbutton class="logoutButton" toplabel="Logout" @click="logout()" />

             <select name="" id="" class='entrySelect selaccount' v-model="location.id"  @change="selectLocation()" v-if="!locationAndTerminalPreselected">
                <option v-for="loc in LocationList" :key="loc.id" :value="loc.id" >
                    {{loc.name}}
                </option>
            </select>

            <select name="" id="" class='entrySelect selaccount' v-model="terminalId"  v-if="location.maxTerminalId>0 && !locationAndTerminalPreselected" @change="selectLocation()" >
                <option v-for="t in terminalList" :key="t.id" :value="t.id" >
                    {{t.name}}
                </option>
            </select>

            <label class="headertext" v-if="locationAndTerminalPreselected">{{location.name}}</label>
            <label class="headertext" v-if="locationAndTerminalPreselected">Terminal {{terminalId}}</label>

            

            
         </div>
       </div>
       <div v-if="loggedIn" class="horizFlexStretch">
        <div class="suspendbuttons">
          <transactions :showButton="trueParam"  v-if="ShowTransactions" buttonClass="shortbutton"/>
          <giftcardbalance :showButton="trueParam" v-if="showGiftcard" buttonClass="shortbutton"/>
        </div>
        
        <div class="topmessage">
          <label class="responsemessage topmessagelabel">{{responseMessage}}</label>
         </div>
        <div class="suspendbuttons">
              <confirm :showButton="trueParam" :body="getSuspendString()" title="Suspend Ticket" @yes="suspendTicket()" v-if="ShowSuspended" buttonclass="shortbutton"/>
              <suspendedtickets :showButton="trueParam" @gototicket="gototicket" @removeticket="removeticket" v-if="ShowSuspended" buttonClass="shortbutton"/>
          </div>
        </div>  
    </div>
</template>

<script>
import datamethods from '../services/datamethods.js'
 import {mapFields} from 'vuex-map-fields'
 import linkbutton from './controls/linkbutton.vue'
 import suspendedtickets from './actions/suspendedtickets.vue'
 import transactions from './actions/transactions.vue'
 import confirm from './actions/confirm.vue'
 import giftcardbalance from './actions/giftcardbalance.vue'
export default {
    name:'svheader',
    components:{
        linkbutton,
        suspendedtickets,
        confirm,
        transactions,
        giftcardbalance
    },
     mixins:[datamethods],
    computed:{
        ...mapFields([
            'loggedInUser',
            'loggedIn',
            'logoImageBasePath',
            'logoOverride',
            'requireLogin',
            'location',
            'pleaseWait',
            'responseMessage',
            'terminalId'
        ]),
        LogoPath(){
            if(this.logoOverride!=''){
                return this.logoImageBasePath + this.logoOverride
            }else{
                return './../' + window.SCHOOL_LOGO
            }
            
        },
        ShowSuspended(){
          if(window.SHOW_SUSPENDED==undefined){return false}
          else{return window.SHOW_SUSPENDED}
        },
        ShowTransactions(){
          if(window.SHOW_TRANSACTIONS==undefined){return false}
          else{return window.SHOW_TRANSACTIONS}
        },
        showGiftcard(){
        if(window.SHOW_GIFTCARD==undefined){return false}
        else{return window.SHOW_GIFTCARD}
      },
    },
    data(){
        return{
            LocationList:[],
            terminalList:[],
            locationAndTerminalPreselected:false,
            falseParam:false,
            trueParam:true
        }
    },
    methods:{
      getSuspendString(){
        return 'Do you want to suspend this ticket?'
      },
        logout(){
            this.$emit('logout')
        },
        async getLocations(){
          
          //call api
          this.$store.dispatch('beforeAPI');
          var response = await this.callAPI(null,'locations_posonly')
          //handle response
      
          if(response.Successful){
            
            this.LocationList= response.locationList
            if(this.LocationList.length>0){
              //console.log('loc ' + this.location.id)
              if(this.location.id==-1){
                this.location.id = this.LocationList[0].id
                this.location.name=this.LocationList[0].name
                
              }else{
                this.locationAndTerminalPreselected=true
              }
              this.selectLocation()
            }

          }else{
            this.responseMessage = response.Message
          }
          this.pleaseWait=false;
        },
        selectLocation(){
         // console.log('selloc ' + this.location.id)
          var locid= this.location.id
          var max=0;
          for(var i = 0; i<this.LocationList.length;i++){
            if (this.LocationList[i].id==locid){
              max=this.LocationList[i].maxTerminalId
              this.location.name=this.LocationList[i].name
            }
          }
          this.location.maxTerminalId=max

          //determine name from list
          /* if(this.location.name ==null){
            for (i = 0; i < this.LocationList.length ; i++) {
              if(this.LocationList[i].id = this.location.id){
                this.location.name=this.LocationList[i].name
              }
            }
          } */

          this.getTerminalList()
        //  console.log('select')
          this.$emit('selectLocation')
        },
        getTerminalList(){
          
          //based on selected location
          var max = this.location.maxTerminalId
          if (max==0){
            this.terminalList=[];
            return;
          }
         // console.log('max ' + max)
          var tlist=[max];
          for(var i = 0; i<max;i++){
            tlist[i]={id:i+1, name:'Terminal ' + (i+1)}
            
          }
         this.terminalList=tlist
        },
        gototicket(ticketId){
          this.$emit('gototicket',ticketId)
        },
        removeticket(ticketId){
          this.$emit('removeticket',ticketId)
        },
        suspendTicket(){
         // console.log('suspend ticket')
          this.$emit('suspendticket')
        }
    },
    mounted(){
        this.getLocations();
    }
}
</script>

<style>
.headerclass{
  background-color:var(--header-color);
 
}
.suspendbuttons{
  padding-bottom:10px;
  display:flex;
  flex-direction:row;
}
.vertFlexTop{
  display:flex;
  flex-direction: column;
  margin:5px;
  align-items: top;
}
.logo{
      height:70px;
  }
  .loginlogo{
      height:80px;
      margin-right:0px;
      margin-top:10px;
    }
    .shortbutton{
      border: none;
      border-radius:10px;
      width:135px;
      height:40px;
      padding:0px;
      font-size: medium;
      margin:10px;
      text-decoration: none;
      background-color:var( --button-color);
      color:var(--light-text-color);

    }
    .topmessage{
      display:flex;
      flex-direction: row;
      align-items:center
    }
    .topmessagelabel{
      vertical-align: middle;
    }
</style>